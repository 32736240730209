import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../stores/UserStore';
import webAppStore from '../../stores/WebAppStore';
import ReferralItem from '../ReferralItem/ReferralItem';
import { ReactComponent as CopyIcon } from '../../images/copy.svg';
import { ReactComponent as UpdateIcon } from '../../images/update.svg';
import './ReferralScreen.scss';

const ReferralScreen = observer(() => {
  const hasReferrals = userStore.Referrals.length > 0;
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loadReferrals = async () => {
    try {
      setIsLoading(true);
      await userStore.fetchReferrals();
    } catch (error) {
      console.error('Ошибка загрузки рефералов:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
    loadReferrals();
  }, []);

  const copuFn = (text) => {
    navigator.clipboard.writeText(text)
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }
  return (
    <div className="referral-screen">
      {isCopied && (
        <div className="copy-notification">
          Ссылка скопирована
        </div>
      )}
      <div className="content">
        <div className="referral-header">
          <div className="referral-code">
            <input
              type="text"
              value={userStore.ReferralCode}
              readOnly
            />
            <button className="copy-button" onClick={() => copuFn(`https://t.me/Foex_Pay_Bot?start=${userStore?.ReferralCode}`)}>
              <div className="copy-icon">
                <CopyIcon />
              </div>
            </button>
          </div>
          <div className='referral-top'>
            <div className="referral-title">
              Топ друзей
              <UpdateIcon style={{ marginLeft: '10px', alignSelf: 'center' }} />
            </div>
            <div className="referral-title-right">
              Получено
            </div>
          </div>
        </div>

        {!hasReferrals && (
          <div className="layout">
            <div className="empty-state">
              <div className='image'></div>
              <div className="empty-text">
                Тут будет список <br /> приглашенных друзей
              </div>
            </div>
          </div>
        )}




        {hasReferrals && (
          <div className="referrals-list">
            {userStore.referrals.map((referral) => (
              <ReferralItem
                key={referral.id}
                name={referral.name}
                amount={referral.amount}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default ReferralScreen;