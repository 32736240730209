import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import Footer from '../Footer/Footer';
import NotificationModal from '../NotificationModal/NotificationModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import webAppStore from '../../stores/WebAppStore';
import './Menu.scss'
import userStore from '../../stores/UserStore';

const Menu = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    const initializeMenu = async () => {
      searchParams.delete("error-background");
      webAppStore.updateBackButtonVisibility();
      try {
        await userStore.fetchUserData();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          userStore.isNewUser = true;
          setShowNotification(true);
        }
      }
      setSearchParams(searchParams);
    };

    initializeMenu();
  }, []);

  return (
<div className='menuPage'>
  <div className='layout'>
    
  </div>
  <div className='headerBlock'>
    <h1 className='headerName'>Foex Pay</h1>
  </div>
  <div className='buttonsBlock'>   
    {[ "/create-request", "/invoice-payment", "/ved", "/aml-check", "/user-profile"].map((path, index) => (
      <Button key={index} type="btnOne" onClick={
        () => {
          if(userStore.isNewUser && index !== 4){
            setShowNotification(true);
          }else{
            navigate(path)
          }   
        }}>
      {index === 4 && "Личный кабинет"}
      {index === 0 && "Обмен USDT"}
      {index === 3 && "AML проверка"}
      {index === 1 && "Оплатить инвойс"}
      {index === 2 && "ВЭД импорт/экспорт"}
      </Button>     
    ))}
  </div>
  <Footer />
  <NotificationModal 
    isOpen={showNotification}
    onClose={() => setShowNotification(false)}
  >
    Для пользования сервисом необходимо заполнить информацию в личном кабинете
  </NotificationModal>
</div>
  );
};

export default Menu;
