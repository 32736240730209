import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './NotificationModal.scss';

const NotificationModal = ({ isOpen, onClose, children }) => {
  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={1000}
        classNames="openBonusFon"
        unmountOnExit
      >
        <div className="modal-overlay" onClick={onClose} />
      </CSSTransition>

      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="openBonusMenu"
        unmountOnExit
      >
        <div className="notification-modal" onClick={e => e.stopPropagation()}>
          <div className="close-button" onClick={onClose}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L13 13M1 13L13 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
          <div className="notification-content">
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default NotificationModal;