import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import TextInput from '../TextInput/TextInput';
import MoneyInput from '../MoneyInput/MoneyInput';
import Button from '../Button/Button';
import Footer from '../Footer/Footer';
import NotificationModal from '../NotificationModal/NotificationModal';
import webAppStore from '../../stores/WebAppStore';
import requestStore from '../../stores/RequestStore';
import './InvoicePayment.scss';

const InvoicePayment = observer(() => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (requestStore.RequestSuccess) {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setShowNotification(false);
        requestStore.resetSuccessState();
        setAmount('');
        setComment('');
        navigate('/');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [requestStore.RequestSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    requestStore.InvoiceRequest = { amount: amount, comment: comment };
    requestStore.createRequestPayment("invoice");
  };

  const handleFileAttach = (files) => {
    requestStore.setInvoiceRequestFiles(files);
  };
  useEffect(() => {
    webAppStore.updateBackButtonVisibility();
  }, []);

  return (
    <div className="invoice-payment">
      <div className='layout'>

      </div>
      <div className="invoice-payment__header">
        <h1 className="invoice-payment__title">Создать заявку на оплату инвойса</h1>
      </div>
      <div className='invoice-payment__input-group'>
        <div className='labelDescription'>Введите сумму</div>
        <MoneyInput
          value={amount}
          onChange={setAmount}
          placeholder="Сумма от 10 000$"
        />
      </div>
      <div className="invoice-payment__input-groupComment">
        <TextInput
          value={comment}
          onChange={setComment}
          placeholder="Комментарий"
          label="Комментарий"
          multiline={true}
          rows={4}
          onFileAttach={handleFileAttach}
        />
      </div>
      <Button type="btnTwo" onClick={handleSubmit} disabled={!amount || !comment}>
        Подтвердить заявку
      </Button>
      <Footer />
      <NotificationModal
        isOpen={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <p>{requestStore.SuccessMessage}</p>
      </NotificationModal>
    </div>
  );
});

export default InvoicePayment;
