import axios from 'axios'
export const api_addr = 'https://foexpaybot.space/api/v1';

const $api = axios.create({
   withCredentials: true,
   baseURL: api_addr,
})

$api.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer ${window?.Telegram?.WebApp?.initData}`
   return config
})

export default $api
