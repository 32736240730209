import React from 'react';
import './ReferralItem.scss';

const ReferralItem = ({ name, username, date }) => {
  return (
    <div className="referral-item">
      <div className="user-info">
        <div className="user-avatar" />
        <div className="user-name">{name}</div>
        <div className="user-name">{username}</div>
      </div>
      <div className="amount">{date}</div>
    </div>
  );
};

export default ReferralItem;