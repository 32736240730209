import { makeAutoObservable, toJS } from 'mobx';
import { ReactComponent as RubleIcon } from '../images/ruble.svg';
import { ReactComponent as UsdtIcon } from '../images/usdt.svg';
import { ReactComponent as UsdIcon } from '../images/usd.svg';
import { ReactComponent as AedIcon } from '../images/aed.svg';
import $api from '../http';
class RequestStore {
  /*activeRequests = [
    { number: 101, type: 'ВЭД', amount: '150,000 USD' },
    { number: 102, type: 'Инвойс', amount: '2,500 EUR' },
    { number: 103, type: 'Обмен', amount: '5 BTC' },
  ];
  requestHistory = [
    { number: 201, type: 'Инвойс', amount: '1,000 USD' },
    { number: 202, type: 'Обмен', amount: '3 ETH' },
    { number: 203, type: 'ВЭД', amount: '75,000 USD' },
  ];*/
  convRequests = [];
  wedRequests = [];
  invoiceRequests = [];

  invoiceRequest = null;
  invoiceRequestFiles = [];
  vedRequest = null;
  vedRequestFiles = [];
  baseAvailableCurrencies = [
    {
      type: "fiat",
      currencies: [
        { name: "usd", icon: <UsdIcon /> },
        { name: "rub", icon: <RubleIcon /> },
        { name: "aed", icon: <AedIcon /> },
      ]
    },
    {
      type: "crypto",
      currencies: [
        { name: "usdt", icon: <UsdtIcon /> },
      ]
    }
  ];
  availableCurrencies = [...this.baseAvailableCurrencies];

  exchangeRates = {
    "usdt": {
      "rub": 100,
      "usd": 1,
      "aed": 2,
    },
  };
  currencyPairs = [];
  currencyTo = this.availableCurrencies[0].currencies[0];
  currencyFrom = this.availableCurrencies[1].currencies[0];
  amountTo = 1;
  amountFrom = '';
  mode = 'toward';
  wallet = '';
  requestSuccess = false;
  successMessage = '';

  constructor() {
    makeAutoObservable(this);
  }
  normalizeCurrencyCode(code) {
    if (!code) return code;

    const upperCode = code.toUpperCase();


    const replacements = {
      'UDST': 'USDT'
    };

    return replacements[upperCode] || upperCode;
  }
  normalizeCurrencyPair(pair) {
    return {
      ...pair,
      code_one: this.normalizeCurrencyCode(pair.code_one),
      code_two: this.normalizeCurrencyCode(pair.code_two)
    };
  }
  processServerData(currencyPairs) {
    const normalizedPairs = currencyPairs.map(pair => this.normalizeCurrencyPair(pair));
    const availableFiat = new Set();
    const availableCrypto = new Set(['USDT']);
    const newExchangeRates = {
      "usdt": {}
    };

    normalizedPairs.forEach(pair => {
      const { code_one, code_two, total_rate_from, total_rate_to } = pair;
      if (code_one === 'USDT') {
        availableFiat.add(code_two.toLowerCase());
        newExchangeRates.usdt[code_two.toLowerCase()] = total_rate_to;
      } else if (code_two === 'USDT') {
        availableFiat.add(code_one.toLowerCase());
        newExchangeRates.usdt[code_one.toLowerCase()] = 1 / total_rate_from;
      }
    });
    const filteredAvailableCurrencies = this.baseAvailableCurrencies.map(group => ({
      type: group.type,
      currencies: group.currencies.filter(currency => {
        if (group.type === 'fiat') {
          return availableFiat.has(currency.name);
        } else if (group.type === 'crypto') {
          return availableCrypto.has(currency.name.toUpperCase());
        }
        return false;
      })
    }));

    this.availableCurrencies = filteredAvailableCurrencies;
    this.exchangeRates = newExchangeRates;
    this.currencyPairs = normalizedPairs;
  }

  async fetchAvailableCurrencies() {
    try {
      const userData = await $api.get('/user/me');
      const currencyPairs = userData.data.currency_pairs;
      this.processServerData(currencyPairs);
    } catch (error) {
      console.error('Ошибка при загрузке доступных валют:', error);
    }
  }
  setVedRequestFiles(files) {
    this.vedRequestFiles = files;
  }

  clearVedRequest() {
    this.vedRequest = null;
    this.vedRequestFiles = [];
  }
  setInvoiceRequestFiles(files) {
    this.invoiceRequestFiles = files;
  }

  clearInvoiceRequest() {
    this.invoiceRequest = null;
    this.invoiceRequestFiles = [];
  }
  set CurrencyTo(currency) {
    this.currencyTo = currency;
  }
  get CurrencyTo() {
    return toJS(this.currencyTo);
  }

  set CurrencyFrom(currency) {
    this.currencyFrom = currency;
  }
  get CurrencyFrom() {
    return toJS(this.currencyFrom);
  }

  set AmountTo(amount) {
    this.amountTo = amount;
  }
  get AmountTo() {
    return toJS(this.amountTo);
  }

  set AmountFrom(amount) {
    this.amountFrom = amount;
  }
  get AmountFrom() {
    return toJS(this.amountFrom);
  }
  set Mode(mode) {
    this.mode = mode;
  }
  get Mode() {
    return toJS(this.mode);
  }
  set InvoiceRequest(value) {
    this.invoiceRequest = value;
  }
  get InvoiceRequest() {
    return toJS(this.invoiceRequest)
  }
  set VedRequest(value) {
    this.vedRequest = value;
  }
  get VedRequest() {
    return toJS(this.vedRequest)
  }
  set AvailableCurrencies(value) {
    this.availableCurrencies = value;
  }
  get AvailableCurrencies() {
    return toJS(this.availableCurrencies);
  }
  get ActiveRequests() {
    return toJS(this.activeRequests);
  }
  set ActiveRequests(value) {
    this.activeRequests = value;
  }
  get RequestHistory() {
    return toJS(this.requestHistory);
  }
  set RequestHistory(value) {
    this.requestHistory = value;
  }
  get Wallet() {
    return toJS(this.wallet);
  }
  setWallet = (value) => {
    this.wallet = value;
  }
  get RequestSuccess() {
    return toJS(this.requestSuccess);
  }

  set RequestSuccess(value) {
    this.requestSuccess = value;
  }

  get SuccessMessage() {
    return toJS(this.successMessage);
  }

  set SuccessMessage(value) {
    this.successMessage = value;
  }
  switchCurrencies() {
    const tempCurrency = this.currencyTo;
    const tempAmount = this.amountTo;

    this.currencyTo = this.currencyFrom;
    this.currencyFrom = tempCurrency;
    this.amountTo = this.amountFrom;
    this.amountFrom = tempAmount;
  }
  setCurrencyTo(currency) {
    if (this.canSelectCurrency(currency, 'to')) {
      this.currencyTo = currency;
      if (this.amountFrom) {
        this.updateAmountTo(this.amountFrom);
      }
    }
  }
  setCurrencyFrom(currency) {
    if (this.canSelectCurrency(currency, 'from')) {
      this.currencyFrom = currency;
      if (this.amountTo) {
        this.updateAmountFrom(this.amountTo);
      }
    }
  }
  getExchangeRateBetweenCurrencies(fromCurrency, toCurrency) {
    if (!fromCurrency || !toCurrency) return 1;

    const normalizedFromCode = this.normalizeCurrencyCode(fromCurrency.name.toUpperCase());
    const normalizedToCode = this.normalizeCurrencyCode(toCurrency.name.toUpperCase());

    const pair = this.currencyPairs.find(p =>
      (p.code_one === normalizedFromCode && p.code_two === normalizedToCode) ||
      (p.code_two === normalizedFromCode && p.code_one === normalizedToCode)
    );

    if (!pair) return 1;

    if (pair.code_one === normalizedFromCode) {
      return pair.total_rate_from;
    } else {
      return pair.total_rate_to;
    }
  }
  updateAmountTo(value) {
    if (value && value !== undefined) {
      this.amountTo = value;
      const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
      console.log(rate)
      this.amountFrom = (parseFloat(value) * rate).toFixed(2);
    } else {
      this.amountFrom = '';
      this.amountTo = '';
    }
  }
  updateAmountFrom(value) {
    if (value) {
      this.amountFrom = value;
      const rate = this.getExchangeRateBetweenCurrencies(this.currencyFrom, this.currencyTo);
      console.log(rate)
      this.amountTo = (parseFloat(value) * rate).toFixed(2);
    } else {
      this.amountFrom = '';
      this.amountTo = '';
    }
  }
  canSelectCurrency(currency, targetField) {
    // Получаем текущую валюту из противоположного поля
    const otherCurrency = targetField === 'to' ? this.currencyFrom : this.currencyTo;

    // Проверяем, не является ли выбираемая валюта той же самой
    if (currency.name === otherCurrency.name) {
      return false;
    }

    // Получаем типы валют
    const targetCurrencyType = this.getCurrencyType(currency);
    const otherCurrencyType = this.getCurrencyType(otherCurrency);

    // Проверяем, не являются ли типы валют одинаковыми
    return targetCurrencyType !== otherCurrencyType;
  }
  async createRequestExchange() {
    try {
      const currentPair = this.currencyPairs.find(p =>
        (p.code_one === this.currencyFrom.name.toUpperCase() && p.code_two === this.currencyTo.name.toUpperCase()) ||
        (p.code_two === this.currencyFrom.name.toUpperCase() && p.code_one === this.currencyTo.name.toUpperCase())
      );

      if (!currentPair) {
        throw new Error('Currency pair not found');
      }

      // Определяем направление транзакции
      const isDirectOrder = currentPair.code_one === this.currencyFrom.name.toUpperCase();
      const transaction_direction = isDirectOrder ? 'to' : 'from';

      const data = {
        currency_pair_id: currentPair.id,
        transaction_direction,
        wallet: this.Wallet,
        amount_from: parseFloat(this.AmountFrom),
        amount_to: parseFloat(this.AmountTo)
      };

      const response = await $api.post('/tr/conv', data);

      if (response.status === 201) {
        this.requestSuccess = true;
        this.successMessage = 'Заявка на обмен успешно создана';
        this.activeRequests.push(response.data);

        this.amountFrom = '';
        this.amountTo = '';
        this.wallet = '';
      }

      return response.data;
    } catch (error) {
      console.error('Ошибка при создании заявки:', error);
      throw error;
    }
  }
  async fetchConvRequests() {
    try {
      const response = await $api.get("/tr/conv");
      this.convRequests = response.data;
    } catch (error) {
      console.error("Failed to fetch conv requests:", error);
    }
  }

  async fetchWedRequests() {
    try {
      const response = await $api.get("/tr/wed");
      this.wedRequests = response.data;
    } catch (error) {
      console.error("Failed to fetch wed requests:", error);
    }
  }

  async fetchInvoiceRequests() {
    try {
      const response = await $api.get("/tr/invoice");
      this.invoiceRequests = response.data;
    } catch (error) {
      console.error("Failed to fetch invoice requests:", error);
    }
  }
  get activeRequests() {
    return [
      ...this.convRequests,
      ...this.wedRequests,
      ...this.invoiceRequests
    ].filter(request => request.status === 0 || request.status === 1)
      .map(request => ({
        number: request.id,
        type: this.getRequestType(request),
        amount: request.amount_to
      }));
  }

  get requestHistory() {
    return [
      ...this.convRequests,
      ...this.wedRequests,
      ...this.invoiceRequests
    ].filter(request => request.status === 2)
      .map(request => ({
        number: request.id,
        type: this.getRequestType(request),
        amount: request.amount_to
      }));
  }
  getRequestType(request) {
    if (this.convRequests.includes(request)) return "Обмен";
    if (this.wedRequests.includes(request)) return "ВЭД";
    if (this.invoiceRequests.includes(request)) return "Инвойс";
    return "unknown";
  }
  async createRequestPayment(type) {
    try {
      const formData = new FormData();

      if (type === "invoice") {
        if (!this.invoiceRequest) {
          throw new Error('Invoice request data is missing');
        }

        formData.append('amount_to', this.invoiceRequest.amount);
        formData.append('comment', this.invoiceRequest.comment);

        if (this.invoiceRequestFiles.length === 1) {
          this.invoiceRequestFiles?.forEach((file, index) => {
            formData.append(`file`, file);
          });
        } else {
          this.invoiceRequestFiles?.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
        }


        const result = await this.createRequest('/tr/invoice', formData);
        if (result.status === 201) {
          this.requestSuccess = true;
          this.successMessage = 'Заявка на оплату инвойса успешно создана';
          this.clearInvoiceRequest();
        }
        return result.data;
      }

      if (type === "ved") {
        if (!this.vedRequest) {
          throw new Error('VED request data is missing');
        }

        formData.append('amount_to', this.vedRequest.amount);
        formData.append('comment', this.vedRequest.comment);

        if (this.vedRequestFiles.length === 1) {
          this.vedRequestFiles?.forEach((file, index) => {
            formData.append(`file`, file);
          });
        } else {
          this.vedRequestFiles?.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
        }


        const result = await this.createRequest('/tr/wed', formData);
        if (result.status === 201) {
          this.requestSuccess = true;
          this.successMessage = 'Заявка ВЭД успешно создана';
          this.clearVedRequest();
        }
        return result.data;
      }
    } catch (error) {
      console.error('Ошибка при создании заявки:', error);
      throw error;
    }
  }
  async createRequest(url, formData) {
    const response = await $api.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }
  resetSuccessState() {
    this.requestSuccess = false;
    this.successMessage = '';
  }
  getCurrencyType(currency) {
    if (!currency) return null;

    for (const group of this.availableCurrencies) {
      if (group.currencies.some(c => c.name === currency.name)) {
        return group.type;
      }
    }
    return null;
  }
}

export default new RequestStore();